import React, {useState} from "react";
import '../index.css'
import logo from '../resources/Logo.png';
import mail_sender from "../apis/mail_sender";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {HiMail} from "react-icons/hi";
import {FaBars} from "react-icons/fa";


// Inquiry Modal - Email Button
function InquiryModal(props) {
    const [email, setEmail] = useState("");
    const [reqTitle, setReqTitle] = useState("Software Development");
    const [description, setDescription] = useState("");
    const [succesAlertShow, setSuccessAlertShow] = useState(false);
    const [errorAlertShow, setErrorAlertShow] = useState(false);

    function ValidateEmail(email)
 
    {
        var atCount = 0
        var atIndex = 0
        var dotIndex=0
        var dotCount =0
        let letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456790"
        for(let i=0; i<email.length ; i++){
            if(email[i]==' '){
                return(false)
            }
            else{
                if(email[i]=='@'){
                    atIndex=i
                    atCount+=1
                }
            }
        }
        if(atCount==1){
            if(letters.includes(email[email.length-1])&&letters.includes(email[atIndex+1])){
                for(let i=atIndex+1; i<email.length; i++){
                    if(email[i]=="."){
                        dotIndex=i
                        dotCount+=1
                    }
                }
                if(dotCount==1){
                    if(letters.includes(email[dotIndex+1])){
                        dotCount=0
                        for(let i=0; i<atIndex; i++){
                            if(email[i]=="."){
                                dotCount+=1
                            }
                        }
                        if(dotCount==1||dotCount==0){
                            return(true)
                        }
                        else{
                            return(false)
                        }
                    }
                    else{
                        return(false)
                    }
                }
                else{
                    return(false)
                }
            }
            else{
                return(false)
            }
        }
        else{
            return(false)
        }
    
    
    }
    
      function send_email(){
        if(!ValidateEmail(email)){
          setErrorAlertShow(true);
          return
        }
        const body = "User Email : "+email + "<br>"+ "Description : "+description
        mail_sender.post("/sendEmail",{
          subject : reqTitle,
          body : body   
      })
      .then((res) => { 
          setSuccessAlertShow(true);
          //Need to add a delay
          window.location.reload()
      })
    // Catch errors if any
    .catch((err) => { 
      alert(err)
      window.location.reload()
    })
      }
    
      function cancel_email(){
        window.location.reload()
      }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Your Inquiry
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="sender-email">@</InputGroup.Text>
                        <Form.Control
                            placeholder="Your email"
                            aria-label="email"
                            aria-describedby="sender-email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </InputGroup>
                    <Form.Select aria-label="Choose your requirement"
                                 onChange={(e) => {
                                     setReqTitle(e.target.value);
                                 }}>
                        <option value="Software Development">Software Development</option>
                        <option value="Art Work">Art Work</option>
                        <option value="Troubleshoot">Troubleshoot</option>
                        <option value="Other">Other</option>
                    </Form.Select><br/>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Description</InputGroup.Text>
                        <Form.Control
                            aria-label="description"
                            aria-describedby="descriptionHelpBlock"
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </InputGroup>
                    <Form.Text id="descriptionHelpBlock" muted>
                        Provide a brief description on your requirement.
                    </Form.Text>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="modal-btn close" onClick={cancel_email}>Cancel</Button>
                <Button className="modal-btn send" onClick={send_email}>Send</Button>
                {succesAlertShow &&
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                        You have submitted the inquiry successfully
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                                onClick={() => setSuccessAlertShow(false)}></button>
                    </div>
                }
                {errorAlertShow &&
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        Email is Null or Invalid
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                                onClick={() => setErrorAlertShow(false)}></button>
                    </div>}
            </Modal.Footer>
        </Modal>
    );
}

export default function NavBar({scrollAbout, scrollContact, scrollServices}) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <div class='container-fluid bg-white custom-nav'>
                <Navbar expand="lg" class="custom-nav">
                    <Container className="menu-nav-items">
                        <Navbar.Brand class='navbar-logo'>
                            <img src={logo} class="navbar-brand nav-logo"/>
                        </Navbar.Brand>
                        <div className="nav-space"></div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" class='toggler'>
                            <span class="toggle-icon"><FaBars class="icon" size={30}/></span>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav style={{marginLeft: 'auto'}}>
                                <Nav.Link className="nav-item" onClick={scrollServices}>OUR SERVICES</Nav.Link>
                                <Nav.Link className="nav-item" onClick={scrollAbout}>ABOUT US</Nav.Link>
                                <Nav.Link className="nav-item" onClick={scrollContact}>CONTACT US</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <Navbar.Brand class='email-item'>
                            <Nav.Link className="nav-item email mt-0" onClick={() => setModalShow(true)}><HiMail size={30}/>EMAIL</Nav.Link>
                        </Navbar.Brand>
                    </Container>
                </Navbar>
            </div>
            {/*Custom-nav*/}

            {/* Modal Calling */}
            <div className="modal-content">
                <InquiryModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </>
    );
}