import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomeStyles.css';
import '../index.css';

import Footer from '../components/Footer'
import About from '../components/About'
import Service from '../components/Service'
import Carousel from '../components/Carousel'
import NavBar from '../components/NavBar'
import Contact from '../components/Contact'

export default function HomePage() {
    const scrollToRef = (ref, options) => () => {
        ref.current.scrollIntoView(options);
    };

    const myRefAbout = React.useRef(null);
    const myRefServices = React.useRef(null);
    const myRefContact = React.useRef(null);

    const scrollToMyRefAbout = scrollToRef(myRefAbout, {behavior: 'smooth', block: 'center'});
    const scrollToMyRefServices = scrollToRef(myRefServices, {behavior: 'instant', block: 'center', inline: 'center'});
    const scrollToMyRefContact = scrollToRef(myRefContact, {behavior: 'smooth', block: 'center'});


    return (
        <>
            <div className="container-fluid full-container">
                <div>
                    <NavBar scrollAbout={scrollToMyRefAbout}
                            scrollServices={scrollToMyRefServices}
                            scrollContact={scrollToMyRefContact}/>
                </div>
                <div className="container-fluid corousel-content-full">
                    <Carousel/>
                </div>
                <div className="container-fluid about-content">
                    <h3 className="section-title text-center mx-auto mb-5" ref={myRefServices}>Our Services</h3>
                    <Service/>
                </div>
                <div className="container-fluid about-content text-center" ref={myRefAbout}>
                    <About/>
                </div>
                <div className="container-fluid about-content text-center" ref={myRefContact}>
                    <Contact/>
                </div>
                <Footer/>
            </div>
        </>
    );
}