import React, {useState} from "react";
import "./ServiceStyles.css"
import service1 from '../resources/service1.png';
import service2 from '../resources/service2.png';
import service3 from '../resources/service3.png';


import { FiArrowRight } from "react-icons/fi";

export default function Service() {
    const [displayImgOne, setDisplayImgOne] = useState(true);
    const [displayImgTwo, setDisplayImgTwo] = useState(true);
    const [displayImgThree, setDisplayImgThree] = useState(true);

    function setVisibleOne() {     
        setDisplayImgOne(false);
        setDisplayImgTwo(true);
        setDisplayImgThree(true);
    }

    function setVisibleTwo() {
        setDisplayImgTwo(false);
        setDisplayImgOne(true);
        setDisplayImgThree(true);
    }

    function setVisibleThree() {
        setDisplayImgThree(false);
        setDisplayImgOne(true);
        setDisplayImgTwo(true);
    }


    const [services, setServices] = useState([
        
                {
                    id: '1', 
                    title: 'Software Development', 
                    imgUrl: service1,
                    description: " Our team of professionals experience in real software development \
                    projects and we have launched this Imart to provide high quality and affordable software products at-on-time to\
                    local and overseas clients with cutting edge technologies.Our software services are\
                    custom software development,Mobile Application development,Enterprise Application\
                    integration,IoT & RPA and cloud infrastructure management",
                    serviceList: ['Web development','Mobile development', 'e-Commerce', 'Digital Marketing']
                },
                {
                    id: '2', 
                    title: 'Logos & Art Works', 
                    imgUrl: service2,
                    description: "  Imart do Graphic designing which are logo, UI design, flyer design,\
                    poster design, brochure design, and graphic design of any kind.\
                    I would love to work with you to achieve your design goal with 100% satisfaction with a\
                    guarantee.",
                    serviceList: ['Logos','Posters', 'Invitation Cards', 'Any Art Work']
                },
                {
                    id: '3', 
                    title: 'Troubleshoot Software/Hardware & IT Services', 
                    imgUrl: service3,
                    description: " Our experts have the required expertise and apply a proven approach to determine\
                    the root cause of problems and help your people fix recurring\
                    issues fast and accurately.",
                    serviceList: ['Laptop, Desktop/PC Upgrading, Service and Repair','Software Troubleshooting','Hardware Troubleshooting', 'Operating Systems', 'Remote Support', 'Virus and Unwanted Software Removal', 'Free Diagnostics']
                },
    ])

    return (
        <>
            <div>
                <div  className="service-img-content">
                {displayImgOne && (<div className="service-img-card">
                    <h2 className='service-img-title'>SOFTWARE DEVELOPMENT</h2>
                    <div className="service-img-1"></div>
                    <button className='btn-on-img' onClick={setVisibleOne}>More Detail <FiArrowRight style={{size:'2vw'}}/> </button>
                </div>)}

                {!displayImgOne && (<div class="container-fluid service-content-card" onClick={()=>{setDisplayImgOne(true)}}>
                    <div className="card-content">
                        <div className="service-content-left">
                            <h3 className="service-content-left-title text-center pt-4">Software Development</h3>
                            <p className="service-content-left-content px-3 pt-3">
                                Our team of professionals experience in real software development projects and
                                we have launched this Imart to provide high quality and affordable software products
                                at-on-time to
                                local and overseas clients with cutting edge technologies.Our software services are
                                custom software development,Mobile Application development,Enterprise Application
                                integration,IoT & RPA and cloud infrastructure management</p>
                        </div>
                        <div className="service-content-right">
                            <ul className="right-content">
                                <li className="service-content-left-content pt-3">Web development</li>
                                <li className="service-content-left-content pt-3">Mobile development</li>
                                <li className="service-content-left-content pt-3">e Commerce</li>
                                <li className="service-content-left-content pt-3">Digital Marketing</li>
                            </ul>
                        </div>
                    </div>
                </div>)}

                {displayImgTwo && (<div className="service-img-card">
                    <h2 className='service-img-title'>LOGOS & ART WORKS</h2>
                    <div className="service-img-2"></div>
                    <button className='btn-on-img' onClick={setVisibleTwo}>More Detail <FiArrowRight style={{size:'2vw'}}/> </button>
                </div>)}

                {!displayImgTwo && (
                <div className="container-fluid service-content-card" onClick={()=>{setDisplayImgTwo(true)}}>
                    <div className="card-content">
                        <div className="service-content-left">
                            <h3 className="service-content-left-title text-center pt-4">Logos & Art Work</h3>
                            <p className="service-content-left-content px-3 pt-3">
                                Imart do Graphic designing which are logo, UI design, flyer design,
                                poster design, brochure design, and graphic design of any kind.
                                I would love to work with you to achieve your design goal with 100% satisfaction with a
                                guarantee.</p>
                        </div>
                        <div className="service-content-right">
                            <ul className="right-content">
                                <li className="service-content-left-content pt-3">Logos</li>
                                <li className="service-content-left-content pt-3">Posters</li>
                                <li className="service-content-left-content pt-3">Invitation Cards</li>
                                <li className="service-content-left-content pt-3">Any Art Work</li>
                            </ul>
                        </div>
                    </div>
                </div>)}

                {displayImgThree && (<div class="service-img-card">
                    <div className="service-img-3"></div>
                    <h2 className='service-img-title'>TROUBLESHOOT - SOFTWARE/HARDWARE & IT SERVICES</h2>
                    <button className='btn-on-img' onClick={setVisibleThree}>More Detail <FiArrowRight style={{size:'2vw'}}/></button>
                </div>)}

                {!displayImgThree && (<div class="container-fluid service-content-card" onClick={()=>{setDisplayImgThree(true)}}>
                    <div className="card-content mt-3">
                        <div className="service-content-left">
                            <h3 className="service-content-left-title text-center pt-4">Troubleshooting</h3>
                            <p className="service-content-left-content px-3 pt-3">Our experts have the required expertise and apply a proven approach to determine
                             the root cause of problems and help your people fix recurring 
                             issues fast and accurately.</p>
                        </div>
                        <div className="service-content-right">
                            <ul className="right-content">
                                <li className="service-content-left-content pt-3">Laptop, Desktop/PC Upgrading, Service and
                                    Repair
                                </li>
                                <li className="service-content-left-content pt-3">Software Troubleshooting</li>
                                <li className="service-content-left-content pt-3">Hardware Troubleshooting</li>
                                <li className="service-content-left-content pt-3">Operating Systems</li>
                                <li className="service-content-left-content pt-3">Remote Support</li>
                                <li className="service-content-left-content pt-3">Virus and Unwanted Software Removal</li>
                                <li className="service-content-left-content pt-3">Free Diagnostics</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>)}
                </div>

                {/* Mobile responsive visible card */}
                <div className="mobile-card-service-container">
                {services.map(item => (
                    <div className="service-card mt-2">
                        <div className="service-card-img"
                        style={{
                            backgroundImage: `url(${item.imgUrl})`
                          }}>  </div>
                            <h4 className="service-card-content-container service-card-title">{item.title}</h4>
                            <p className="service-card-content-container service-card-description">
                                {item.description}
                            </p>
                               {item.serviceList?.map (item=>(<p className="service-card-list">{item}</p>))}
                      
                    </div>
                ))}
                </div>
            </div>

        </>
    );
}