import React from "react";
import "./ComponentStyles.css";
import carousel1 from "../resources/A.jpg";
import carousel2 from "../resources/B.jpg";
import carousel3 from "../resources/C.jpg";
import { animated, useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";
import Carousel from 'react-bootstrap/Carousel';

export default function CarouselSlide()
{
  const CarouselImages=[
    carousel1,
    carousel2,
    carousel3,
    carousel1,
    carousel2,
    carousel3,
  ];
  const [style, set, scroll] = useSpring(() => ({
    transform: "perspective(500px) rotateY(0deg)",
    scroll: (CarouselImages.length-1)*50,
    from: { scroll: CarouselImages[0]},
    reset: true,
    delay: 200,
  }));

  const clamp = (value, clampAt = 20) => {
    if (value > 0) {
      return value > clampAt ? clampAt : value;
    } else {
      return value < -clampAt ? -clampAt : value;
    }
  };


  const bind = useScroll(event => {
    set({
      transform: `perspective(500px) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`
    });
  });

   return(
    <>
   <div class="carousel-container" {...bind()} id="carouselExampleControls" data-ride="carousel" data-interval="1000">
        {CarouselImages.map(src => (
          <animated.div
          
            key={src}
            className="carousel-card"
            style={{
              ...style,
              autoPlay:'true',
              backgroundImage: `url(${src})`
            }}
            scrollLeft={scroll}
          />
        ))}
      </div>

<div className="lg-carousel">
<Carousel>
      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={carousel1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-title">Software Development</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={carousel2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-title">Logos & Art Works</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={carousel3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-title">Troubleshoot Software/Hardware & IT Services</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
</div>
    </>
   );
}