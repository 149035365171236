import React from "react";

export default function Contact()
{
    return(
    <>
    <h3 class="section-title mx-auto mt-5">Contact Us</h3><br/>
    <div class="container-fluid text-center contact"> 
      <table class="contact-table">
        <tr>
          <td class="contact-title col-sm-6">Email</td>
          <td class="contact-data col-sm-6">info@imart.co.uk</td>
        </tr>
        <tr>
          <td class="contact-title col-sm-6">Call/ Whatsapp</td>
          <td class="contact-data col-sm-6">+447466507881</td>
        </tr>
        <tr>
          <td class="contact-title col-sm-6">Address</td>
          <td class="contact-data col-sm-6">14 A Seller’s Wheel, Sheffield</td>
        </tr>
      </table>
    </div>
    </>
    );
}