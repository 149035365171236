import React from "react";
import '../screens/HomeStyles.css';

export default function About() {
    return (
        <>
            <div>
                <h3 class="section-title mx-auto mt-5">About Us</h3>
                <p class="about-section-content mx-auto">
                    Imart is a Company that provides IT & Graphic solutions including a range of facilities which are IT
                    Support,
                    Graphic Design, Software Solutions & Development, Web Development, and Hardware solutions started in
                    January 2023.
                    Currently, we are serving Individuals, Small Scale to Medium Businesses, and more leading corporate
                    bodies.
                </p>
            </div>

        </>
    );
}