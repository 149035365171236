import React from "react";
import '../screens/HomeStyles.css';
import logo from '../resources/logo.jpg';

export default function Footer()
{
    return(
        <>
    <div>
    <footer class="container">
    <div class="row">
      <div class="logo-section col-sm-2">
        <img class="footer-logo" src={logo}/>
      </div>
      <div class="footer-service-section col-sm-10">
        <h5 class="text-center footer-heading">Services</h5>
        <table>
            <tr>
              <td class="footer-service">
                <ul>
                  <li class="footer-title">Software Development</li>
                  <li>Web Development</li>
                  <li>Mobile Development</li>
                  <li>e-Commerce</li>
                  <li>Digital Marketing</li>
                </ul>
              </td>

              <td class="footer-service">
                <ul>
                  <li class="footer-title pt-10">Troubleshoot</li>
                  <li>Laptop, Desktop/ PC Upgrading Service and Repair</li>
                  <li>Software Troubleshooting</li>
                  <li>Hardware Troubleshooting</li>
                  <li>Operating Systems</li>
                  <li>Remote Support</li>
                  <li>Virus and Unwanted Software Removal</li>
               
                </ul>
              </td>

              <td class="footer-service">
                <ul>
                  <li class="footer-title">Logo and Art Work</li>
                  <li>Logos</li>
                  <li>Posters</li>
                  <li>Invitation Cards</li>
                  <li>Any ArtWork</li>
                </ul>
              </td>
          </tr>
          </table>
        </div>
    </div>
    <p style={{textAlign:'center', paddingBottom: '20px'}} class="copywright">© 2023 iMart. All rights reserved.</p>
</footer>
</div>
        </>
    );
}